import InProgress from "./app/pages/inProgress/InProgress";


const App = () => {
  return (
    <div className="App">
      <InProgress/>
    </div>
  );
}

export default App;
