import React from 'react'
import Loading from '../../components/loading/Loading'

const InProgress = () => {
    return (
        <div className='inprogress'>
            <Loading/>
            <h1>Web Developer Portfolio</h1>
            <span>In progress</span>
        </div>
    )
}

export default InProgress